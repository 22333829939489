import React, { useEffect } from "react";
import "./styles/About.css";
import "aos/dist/aos.css";
import AOS from "aos";
import { motion } from "framer-motion";

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration
    });
  }, []);

  return (
    <div className="about">
      <div className="about-container">
        {/* Hero Section */}
        <div className="hero-section">
          <h1 className="about-heading" data-aos="fade-down">
            Hello, I'm Tyler Witkowski
          </h1>
          <p className="about-subheading" data-aos="fade-up">
            Welcome to my journey as a photographer and the story behind TjWit
            Media.
          </p>
          <motion.button
            className="cta-button"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            data-aos="fade-up"
          >
            <a href="/portfolio">View My Work</a>
          </motion.button>
        </div>

        {/* Story Section */}
        <div className="story-section">
          <div className="story-content">
            <div className="story-text" data-aos="fade-right">
              <p>
                During my first few years at Priory, I attended “Raven Roundup,”
                an event showcasing school clubs. Initially, I dabbled in a few
                extracurriculars but never found my true passion. That all
                changed on March 22nd, 2022, when I attended the Priory Ravens'
                Wickenheiser Cup finals and decided to take pictures at the
                game. From that moment on, my journey as a photographer began.
              </p>
            </div>
            <div className="story-image" data-aos="fade-left">
              <img src="/assets/080.jpg" alt="Tyler Witkowski" />
            </div>
          </div>

          <div className="story-content">
            <div className="story-image" data-aos="fade-right">
              <img src="/assets/SMC_PIC.jpg" alt="2024 Sports Media Club" />
            </div>
            <div className="story-text" data-aos="fade-left">
              <p>
                Fast forward to 2023, I founded the Priory Sports Media Club,
                mentoring underclassmen with a shared passion for sports media.
                This club evolved to create and manage Priory's Athletics
                Instagram (@stlprioryathletics) account, posting game day
                graphics, final score updates, and event photos on my personal
                photography pages (@prioryphotos and @tjwit.media). Today, it
                has grown into a thriving club that has big changes coming soon!
              </p>
            </div>
          </div>
        </div>

        {/* Timeline Section */}
        <div className="timeline-section">
          <div className="timeline-event" data-aos="fade-right">
            <h3>March 22, 2022</h3>
            <p>Took my first sports photos at the Wickenheiser Cup finals.</p>
          </div>
          <div className="timeline-event" data-aos="fade-left">
            <h3>November 24, 2022</h3>
            <p>Created the @prioryphotos Instagram page.</p>
          </div>
          <div className="timeline-event" data-aos="fade-right">
            <h3>September 19, 2023</h3>
            <p>Founded the Sports Media Club.</p>
          </div>
          <div className="timeline-event" data-aos="fade-left">
            <h3>November 30, 2023</h3>
            <p>Created the @stlprioryathletics Instagram page.</p>
          </div>
          <div className="timeline-event" data-aos="fade-right">
            <h3>2024</h3>
            <p>Attended 84 sporting events & founded TjWit Media.</p>
          </div>
        </div>

        {/* Vision Section */}
        <div className="vision-section" data-aos="fade-up">
          <p>
            Photography is more than just a hobby for me; it's a way to capture
            the raw emotions and stories of high school athletics. In 2024
            alone, I attended 84 events to preserve these unforgettable moments.
            With TjWit Media, I hope to continue this journey and inspire others
            to find their passions.
          </p>
          <div className="vision-image" data-aos="fade-left">
            <img src="/assets/027.jpg" alt="Tyler Witkowski" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
