import React from "react";
import "./styles/Home.css";

const Home = () => {
  return (
    <div className="home">
      <div className="image-slider">
        {/* Dynamic background images */}
        <div
          className="slide"
          style={{
            backgroundImage:
              "url('https://thesis.tjwit.com/images/determination.jpg')",
          }}
        ></div>
        <div
          className="slide"
          style={{
            backgroundImage:
              "url('https://thesis.tjwit.com/images/excitement.jpg')",
          }}
        ></div>
        <div
          className="slide"
          style={{
            backgroundImage:
              "url('https://thesis.tjwit.com/images/teamwork.jpg')",
          }}
        ></div>
        <div
          className="slide"
          style={{
            backgroundImage:
              "url('https://thesis.tjwit.com/images/victory-defeat.jpg')",
          }}
        ></div>
        <div
          className="slide"
          style={{
            backgroundImage:
              "url('https://thesis.tjwit.com/images/favorites.jpg')",
          }}
        ></div>
      </div>
      <div className="overlay"></div>
      <h1 className="home-title">TjWit Media</h1>
    </div>
  );
};

export default Home;
