import React from "react";
import "./styles/Portfolio.css";
import portfolioData from "../utils/portfolioData.json";

const Portfolio = () => {
  return (
    <div className="portfolio-page">
      <h1 className="portfolio-title">Portfolio</h1>
      <p className="portfolio-description">
        Explore my most recent work! Click on an album to view more!
      </p>
      <button
        className="support-button"
        onClick={() => window.open("https://buymeacoffee.com/tjwit", "_blank")}
      >
        <span className="bounce-heart">❤️</span> Support My Work
      </button>

      <div className="portfolio-grid">
        {portfolioData.map((album, index) => (
          <div
            key={index}
            className="portfolio-card"
            style={{
              animationDelay: `${index * 0.2}s`, // Incremental delay per card
            }}
            onClick={() => window.open(album.link, "_blank")}
          >
            <div
              className="portfolio-image"
              style={{
                backgroundImage: `url(${album.previewImage})`,
              }}
            ></div>
            <div className="album-info">
              <h2 className="album-name">{album.name}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
