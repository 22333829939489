import React from "react";
import { useParams } from "react-router-dom";
import "./styles/ClientPage.css";
import clientData from "../utils/clientData.json";

const ClientPage = () => {
  const { clientID } = useParams();

  // Find the client based on the clientID
  const client = clientData.find((c) => c.clientID === clientID);

  // Handle missing client case
  if (!client) {
    return <p>Client not found!</p>;
  }

  return (
    <div className="client-page">
      <h1>Welcome, {client.clientName}!</h1>
      <p>Your albums are listed below:</p>
      <div className="albums-container">
        {client.albums.map((album, index) => (
          <div className="album-card" key={index}>
            <h2>{album.displayName}</h2>
            <button
              className="album-button"
              onClick={() => window.open(album.albumLink, "_blank")}
            >
              <img
                src={album.albumPicture}
                alt={album.displayName}
                className="album-image"
              />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientPage;
