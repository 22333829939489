import React, { useState } from "react";
import "./styles/Booking.css";
import emailjs from "@emailjs/browser";

const questions = [
  { id: 1, type: "text", question: "What is your name?", required: true },
  {
    id: 2,
    type: "email",
    question: "What is your email address?",
    required: true,
    validate: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), // Email validation
  },
  {
    id: 3,
    type: "choice",
    question: "What type of photography session are you looking for?",
    options: ["Sports", "Family", "Portrait", "Candid"],
    required: true,
  },
  {
    id: 4,
    type: "choice",
    question: "Is this sports session for a single player or the entire team?",
    condition: (answers) => answers[3] === "Sports",
    options: ["Single Player", "Entire Team"],
    required: true,
  },
  {
    id: 5,
    type: "text",
    question: "What is the player's name?",
    condition: (answers) => answers[4] === "Single Player",
    required: true,
  },
  {
    id: 6,
    type: "number",
    question: "What is the player's number?",
    condition: (answers) => answers[4] === "Single Player",
    required: true,
    validate: (value) => !isNaN(value) && Number.isInteger(Number(value)), // Must be an integer
  },
  {
    id: 7,
    type: "text",
    question: "What is the team's name?",
    condition: (answers) => answers[4] === "Entire Team",
    required: true,
  },
  {
    id: 8,
    type: "text",
    question: "What event or game will I be photographing?",
    condition: (answers) => answers[3] === "Sports",
    required: true,
  },
  {
    id: 9,
    type: "text",
    question: "Where will the session take place?",
    condition: (answers) =>
      ["Family", "Portrait", "Candid"].includes(answers[3]),
    required: true,
  },
  {
    id: 10,
    type: "text",
    question: "Do you have a specific theme or style in mind?",
    condition: (answers) =>
      ["Family", "Portrait", "Candid"].includes(answers[3]),
  },
  {
    id: 11,
    type: "date",
    question: "Select a date for your session.",
    required: true,
  },
  {
    id: 12,
    type: "time",
    question: "What time will the event start?",
    required: true,
  },

  {
    id: 13,
    type: "text",
    question:
      "Any additional details you'd like to share? (Arrival time, special requests, certian type of delivery etc.)",
  },
];

const Booking = () => {
  const [showInfo, setShowInfo] = useState(true); // Controls whether to show the info section
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [animationClass, setAnimationClass] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false); // Success state

  const handleContinue = () => {
    setAnimationClass("slide-out-down");
    setTimeout(() => {
      setShowInfo(false); // Hide the info section
      setAnimationClass("slide-in-right"); // Set animation for the first question
    }, 500); // Delay to ensure smooth transition
  };

  const handleAnswer = (value) => {
    const currentQuestion = questions[currentStep];

    // Validation logic
    if (currentQuestion.required && !value.trim()) {
      setErrorMessage("This field is required.");
      return;
    }

    if (currentQuestion.validate && !currentQuestion.validate(value)) {
      setErrorMessage("Invalid input. Please follow the specified format.");
      return;
    }

    setErrorMessage("");
    setAnswers((prev) => ({ ...prev, [currentQuestion.id]: value }));

    let nextStep = currentStep + 1;
    while (
      nextStep < questions.length &&
      questions[nextStep].condition &&
      !questions[nextStep].condition({
        ...answers,
        [currentQuestion.id]: value,
      })
    ) {
      nextStep++;
    }

    if (nextStep < questions.length) {
      setAnimationClass("slide-out-left");
      setTimeout(() => {
        setCurrentStep(nextStep);
        setAnimationClass("slide-in-right");
      }, 300);
    } else {
      const finalAnswers = { ...answers, [currentQuestion.id]: value };
      sendEmail(finalAnswers);
    }
  };

  const goBack = () => {
    let prevStep = currentStep - 1;
    while (
      prevStep >= 0 &&
      questions[prevStep].condition &&
      !questions[prevStep].condition(answers)
    ) {
      prevStep--;
    }

    if (prevStep >= 0) {
      setAnimationClass("slide-out-right");
      setTimeout(() => {
        setCurrentStep(prevStep);
        setAnimationClass("slide-in-left");
      }, 300);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (
        questions[currentStep].type === "text" ||
        questions[currentStep].type === "email" ||
        questions[currentStep].type === "number"
      ) {
        handleAnswer(e.target.value);
      }
    }
  };

  const sendEmail = (finalAnswers) => {
    emailjs.init({ publicKey: "uDHtkIMnNaTr6pJ9Z" });

    emailjs
      .send("service_jtd83vf", "template_n6099vr", {
        emailID: Math.floor(Math.random() * 100),
        formData: Object.values(finalAnswers).join("\n\n"),
        userName: finalAnswers[1],
        userEmail: finalAnswers[2],
      })
      .then(() => setSuccess(true))
      .catch(() => alert("There was an error submitting your booking."));
  };

  return (
    <div className="booking-container">
      {success && (
        <div className="success-modal">
          <div className="success-content">
            <h2>🎉 Booking Submitted Successfully! 🎉</h2>
            <p>
              Thank you for your response! Please check your inbox for a
              confirmation email, and we will be in touch shortly!
            </p>
            <a className="done-btn" href="/">
              Home
            </a>
          </div>
        </div>
      )}

      {/* Info Section */}
      {showInfo && (
        <div className={`info-section ${animationClass}`}>
          <h1>Welcome to the TjWit Media Booking Page!</h1>
          <p>
            Here you can book your photography session. Choose from options like
            sports, family, portrait, or candid photography. At TjWit Media, I
            strive to provide the most competative prices in the industry, while
            also being flexible. For this reason, I do not have set prices per
            se, but rather once you submit your booking request, the response
            you get from me personally will contain a quote, based off of
            various factors listed in your request. If you would like to learn
            more about my pricing, please feel free to contact me by clicking{" "}
            <a href="mailto:tylerwit@gmail.com">here</a>!
          </p>
          <button className="continue-btn" onClick={handleContinue}>
            Continue
          </button>
        </div>
      )}

      {/* Booking Form */}
      {!showInfo && (
        <>
          <div className="progress-bar">
            <div
              className="progress"
              style={{
                width: `${((currentStep + 1) / questions.length) * 100}%`,
              }}
            ></div>
          </div>

          <div className={`question-card ${animationClass}`}>
            <p className="question">{questions[currentStep].question}</p>

            {errorMessage && <p className="error-message">{errorMessage}</p>}

            {questions[currentStep].type === "text" && (
              <input
                type="text"
                placeholder="Type your answer..."
                value={answers[questions[currentStep].id] || ""} // Ensure input shows only the value for the current question
                onChange={(e) =>
                  setAnswers((prev) => ({
                    ...prev,
                    [questions[currentStep].id]: e.target.value,
                  }))
                }
                onKeyDown={handleKeyDown}
              />
            )}

            {questions[currentStep].type === "email" && (
              <input
                type="email"
                placeholder="Type your email..."
                value={answers[questions[currentStep].id] || ""} // Ensure input shows only the value for the current question
                onChange={(e) =>
                  setAnswers((prev) => ({
                    ...prev,
                    [questions[currentStep].id]: e.target.value,
                  }))
                }
                onKeyDown={handleKeyDown}
              />
            )}

            {questions[currentStep].type === "number" && (
              <input
                type="number"
                placeholder="Type your answer..."
                value={answers[questions[currentStep].id] || ""} // Ensure input shows only the value for the current question
                onChange={(e) =>
                  setAnswers((prev) => ({
                    ...prev,
                    [questions[currentStep].id]: e.target.value,
                  }))
                }
                onKeyDown={handleKeyDown}
              />
            )}

            {questions[currentStep].type === "choice" && (
              <div className="choice-options">
                {questions[currentStep].options.map((option) => (
                  <button
                    key={option}
                    onClick={() => handleAnswer(option)}
                    className="choice-button"
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}

            {questions[currentStep].type === "date" && (
              <div className="date-picker-container">
                <input
                  type="date"
                  value={answers[questions[currentStep].id] || ""} // Ensure the date value is specific to this question
                  onChange={(e) =>
                    setAnswers((prev) => ({
                      ...prev,
                      [questions[currentStep].id]: e.target.value,
                    }))
                  }
                />
              </div>
            )}

            {questions[currentStep].type === "time" && (
              <div className="time-input-container">
                <input
                  type="time"
                  value={answers[questions[currentStep].id] || ""}
                  onChange={(e) =>
                    setAnswers((prev) => ({
                      ...prev,
                      [questions[currentStep].id]: e.target.value,
                    }))
                  }
                />
              </div>
            )}
          </div>

          <div className="navigation-buttons">
            {currentStep > 0 && (
              <button className="forward-button" onClick={goBack}>
                Back
              </button>
            )}
            {questions[currentStep].type !== "choice" && (
              <button
                className="forward-button"
                onClick={() =>
                  handleAnswer(
                    document.querySelector(".question-card input")?.value || ""
                  )
                }
              >
                {currentStep === questions.length - 1 ? "Submit" : "Next"}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Booking;
